$(document).foundation();
$(document).ready(function () {
    initFullSlider();
    initSlider();
    initGallerySlider();
    initImageHotspots();
    initDetailGallery();
    initSlideout();
    initSmoothScroll();
    initToggleInView();
    initLandingFilters();
    initGridBlockFilters();
    //initReveals();
    initCountdowns();
    initVideos();
    initBookingWidget();
    initAccordiom();
    initLocationMap();
    //initNewsletter();
    initLazyLoading();
    initHomeHeading();
});
$('.meet-the-team').masonry({
    // options
    itemSelector: '.grid-item',
    columnWidth: 304
});

function initFullSlider() {
    $('.full-slider').slick({
        speed: 4000,
        animationSpeed: 300,
        autoplay: true,
        arrows: true,
        fade: true,
        prevArrow: '<button type="button" class="slick-prev slider__prev"><i class="icon-arrow-circle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next slider__next"><i class="icon-arrow-circle-right"></i></button>',
        dots: true,
    });




    // start first video
    $('.full-slider .slick-active video').each(function (i, v) {
        v.play();
    });

    // when slides change, stop/start video
    $('.full-slider').on('afterChange', function (event, slick, currentSlide, nextSlide) {
        $('.full-slider .slick-slide video').each(function (i, v) {
            v.pause();
        });
        $('.full-slider .slick-active video').each(function (i, v) {
            v.play();
        });
    });
}

function initSlider() {
    $('.slider').slick({
        autoplaySpeed: 3000,
        speed: 1000,
        autoplay: true,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev slider__prev"><i class="icon-arrow-circle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next slider__next"><i class="icon-arrow-circle-right"></i></button>',
        fade: true
    });
}

function initImageHotspots() {
    $('.detail-image__hotspot').click(function (e) {
        var hotspot = $(this);

        // check if current popup is visible
        var popup = hotspot.next('.detail-image__hotspot-popup');
        var isVisible = popup.is(':visible');

        // hide all popups in this image
        hotspot
        .parent()
        .find('.detail-image__hotspot-popup')
        .hide();

        if (!isVisible) {
            // show the clicked one
            popup.show();
        }
    });
}

function initDetailGallery() {
    $('.open-detail-gallery').on('click', function() {
        var detailGallery = $('.detail-gallery')
        var slideContent = detailGallery.find('.slide-out__content');

        var showSlideout = function () {
            slideContent.show();
        };


        // load content through ajax
        $.ajax({
            url: slideContent.data('content-ajax'),
            success: function (data) {
                slideContent.html(data);
                slideContent.attr('data-content-ajax', '');
                initGallerySlider(slideContent);
                showSlideout();
            }
        });
    });
}

function initSlideout() {
    $('[data-tab-name], a[href^="#tab_"]').click(function (e) {
        var tab, otherTabs;
        var tag = $(this);
        var href = tag.attr('href');
        // check if trigger was anchor link
        if (href && href.indexOf('#tab_') >= 0) {
            var tabName = tag.attr('href').replace('#tab_', '');
            tab = $('[data-tab-name="' + tabName + '"]');
            otherTabs = $('[data-tab-name="' + tabName + '"]').not(tab);
        } else {
            tab = tag;
            otherTabs = $('[data-tab-name]').not(tab);
        }
        var slideOut = $('.slide-out');
        var isTab = tab.hasClass('slide-out__tab');
        var isActive = tab.hasClass('slide-out__tab--active');

        // clear other tabs
        if (isTab) {
            otherTabs.removeClass('slide-out__tab--active');
        }
        slideOut
        .find('.slide-out__content')
        .not('[data-content-name="' + tab.data('tab-name') + '"]')
        .hide();

        if (isActive) {
            // close the slide out
            slideOut.foundation('close', e, tab);
            if (isTab) {
                tab.removeClass('slide-out__tab--active');
            }
        } else {
            // open the slide out
            var slideContent = slideOut.find('.slide-out__content[data-content-name="' + tab.data('tab-name') + '"]');

            var showSlideout = function () {
                slideContent.show();
                slideOut.foundation('open', e, tab);
                $(window).scrollTop(0);
                if (isTab) {
                    tab.addClass('slide-out__tab--active');
                }
                history.replaceState({}, document.title, '#tab_' + tab.data('tab-name'));
            };

            if (slideContent.data('content-ajax')) {
                // load content through ajax
                $.ajax({
                    url: slideContent.data('content-ajax'),
                    success: function (data) {
                        slideContent.html(data);
                        slideContent.attr('data-content-ajax', '');
                        initGallerySlider(slideContent);
                        showSlideout();
                    }
                });
            } else {
                // nothing to load, just show the slide out
                showSlideout();
            }
        }

        e.preventDefault();
    });

    $('.slide-out').on('closed.zf.offcanvas', function (e) {
        // update page hash
        history.replaceState({}, document.title, window.location.pathname + window.location.search);

        // remove button active state
        $('.slide-out__tab').removeClass('slide-out__tab--active');

        var wrapper = $('.off-canvas-wrapper');

        // clear fixed height on wrapper
        wrapper.css('height', '');

        // clear fixed min-height on content
        wrapper.find('.content-left').css('min-height', '');
    });

    $('.slide-out').on('opened.zf.offcanvas', function (e) {
        // set wrapper height to fit the offcanvas menu in full
        var wrapper = $('.off-canvas-wrapper');
        var slideOut = $(this);
        var wrapperHeight = wrapper.outerHeight();
        var offCanvasHeight = slideOut.find('.slide-out__content').outerHeight() + slideOut.offset().top;

        if (offCanvasHeight > wrapperHeight) {
            wrapper.css('height', offCanvasHeight);
        }

        // adjust minimum-height of the content (excluding header and footer)
        wrapper.find('.content-left').css('min-height', offCanvasHeight - 115);
    });

    var tab = '';

    if (window.location.hash.indexOf('#tab_') === 0) {
        // modern browsers
        tab = window.location.hash.substr(5);
    } else if (window.location.hash.indexOf('#/tab_') === 0) {
        // used in IE9 polyfill
        tab = window.location.hash.substr(6);
    }

    if (tab.length > 0) {
        // show selected tab
        $('[data-tab-name="' + tab + '"]').trigger('click');
    }

    // close any slide outs when the burger menu is opened
    $('.header__menu-btn').on('click', function () {
        $('.slide-out').foundation('close');
    });
}



function initGallerySlider(root) {
    if (!root) {
        // no root defined, apply it to whole document
        root = $(document);
    }

    root.find('.gallery-slider').slick({
        speed: 4000,
        animationSpeed: 300,
        autoplay: true,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev gallery-slider__prev"><i class="icon-arrow-circle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next gallery-slider__next"><i class="icon-arrow-circle-right"></i></button>'
    });

    $(window).trigger('resize.slick');

    if (root.get(0).nodeName !== '#document') {
        return;
    }

    $('.slide-out').on('opened.zf.offcanvas', function (e) {
        var gallery = $(this).find('.slide-out__content:visible .gallery-slider');

        if (gallery.length === 0) {
            return;
        }

        // start the slider
        gallery.slick('slickPlay');
    });

    $('.slide-out').on('closed.zf.offcanvas', function (e) {
        var gallery = $(this).find('.slide-out__content:visible .gallery-slider');

        if (gallery.length === 0) {
            return;
        }

        // stop the slider
        gallery.slick('slickPause');
    });
}

function initSmoothScroll() {
    $('[data-smooth-scroll]').click(function (e) {
        var target = $($(this).attr('href'));

        if (target.length === 0) {
            return;
        }

        // scroll to the location of the found element
        $('html, body')
        .stop(true)
        .animate({ scrollTop: target.offset().top - 55 });

        e.preventDefault();
    });
}

function initToggleInView() {
    if ($('[data-toggle-in-view]').length === 0) {
        // nothing to toggle
        return;
    }

    $(window).on('scroll.toggle-in-view', function (e) {
        var screenBottom = $(window).scrollTop() + window.innerHeight;

        $('[data-toggle-in-view]').each(function (i, el) {
            var elem = $(el);

            if (elem.offset().top + 100 > screenBottom) {
                // element not in view yet
                return;
            }

            // toggle class
            elem.addClass(elem.data('toggle-in-view'));

            // remove attribute
            elem.removeAttr('data-toggle-in-view');
        });

        if ($('[data-toggle-in-view]').length === 0) {
            // all elements visible, remove scroll event handler
            $(window).off('scroll.toggle-in-view');
        }
    });
}

function initLandingFilters() {

    $('button[data-landing-filter]').on('click', function () {
        var btn = $(this);

        btn.siblings('button[data-landing-filter]').removeClass('content-left__filter-list-item--active');
        btn.addClass('content-left__filter-list-item--active');

        var filterCategory = btn.data('landing-filter');

        // reset landing blocks
        $('div[data-landing-filter-categories]').show();

        if (filterCategory && filterCategory.length > 0) {
            // hide any blocks that don't contain the matching filter category
            $('div[data-landing-filter-categories]').each(function () {
                var div = $(this);

                if (div.data('landing-filter-categories').indexOf(filterCategory) < 0) {
                    div.hide();
                }
            });
        }
    });

}

function initGridBlockFilters() {
    if ($('.grid-block__filter-button').length === 0) {
        // nothing to filter
        return;
    }

    var filterItems = $('[data-filter-categories]');

    var triggerReflow = function () {
        // get current block size
        var blockSize;

        switch (Foundation.MediaQuery.current) {
            case 'small':
            blockSize = 1;
            break;
            case 'medium':
            case 'large':
            case 'xlarge':
            blockSize = 2;
            break;
            case 'xxlarge':
            blockSize = 3;
            break;
        }

        // update clear settings on visible blocks
        filterItems.css('clear', 'none');
        filterItems
        .not(':hidden')
        .filter(function (i) {
            return i % blockSize == 0;
        })
        .css('clear', 'both');
    };

    $('.grid-block__filter-button').on('click', function (e) {
        var selectedCategory = $(this).data('filter-category');

        $(this)
        .siblings('.grid-block__filter-button')
        .removeClass('grid-block__filter-button--active');

        $(this).addClass('grid-block__filter-button--active');

        // only show elements with selected category
        filterItems
        .fadeOut()
        .promise()
        .done(function () {
            filterItems
            .each(function () {
                if ($(this).is('[data-filter-categories~="' + selectedCategory + '"]')) {
                    $(this).fadeIn();
                }
            })
            .promise()
            .done(triggerReflow);
        });

        e.preventDefault();
    });
}

/*
function initReveals() {
    // open reveals marked as active
    var activeReveals = $('.reveal--active');

    if (activeReveals.length === 0) {
        return;
    }

    activeReveals.foundation('open');

   $('main').addClass('blur');

    activeReveals.one('closed.zf.reveal', function () {
        // trigger any slider to resize
        $('.slick-initialized').slick('refresh');
        $('main').removeClass('blur');
    });
}

function initNewsletter() {
    var signupPopup = $('#signup-popup');

    if (signupPopup.length === 0) {
        return;
    }

    // open newsletter popup when link is set to it
    $('a[href="#newsletter-signup"]').on('click', function (e) {
        signupPopup.foundation('open');

        e.preventDefault();
        return false;
    });
}

*/

function initCountdowns() {
    var countdowns = $('.js-countdown');

    if (countdowns.length === 0) {
        // nothing to count down
        return;
    }

    countdowns.each(function (i, el) {
        var $el = $(el);
        var date = new Date($el.data('countdown').replace(/-/g, "/"));

        countdown(
            date,
            function (ts) {
                $el.find('.js-countdown-weeks').html(ts.weeks);
                $el.find('.js-countdown-days').html(ts.days);
                $el.find('.js-countdown-hours').html(ts.hours);
                $el.find('.js-countdown-minutes').html(ts.minutes);
            },
            countdown.WEEKS | countdown.DAYS | countdown.HOURS | countdown.MINUTES
            );
    });
}

function initVideos() {
    var videos = $('.rte').find('iframe[src*="vimeo.com"], iframe[src*="youtube.com"]');

    if (videos.length === 0) {
        // no videos found
        return;
    }

    videos.each(function (i, el) {
        var $el = $(el);

        // calculate wrapper padding based on video aspect ratio
        var wrapperPaddingBottom = 56.25; // default to 16:9

        var width = parseInt($(el).attr('width'));
        var height = parseInt($(el).attr('height'));

        if (!isNaN(width) && !isNaN(height)) {
            wrapperPaddingBottom = height / width * 100;
        }

        $el.wrap('<div class="video-wrapper" style="padding-bottom: ' + wrapperPaddingBottom + '%;"></div>');
    });
}

function initBookingWidget() {
    $('.header__links .cta-text__link').on('click', function (e) {
        var slideOuts = $('.slide-out');
        if (slideOuts.length > 0) {
            slideOuts.foundation('close'); // close any slideout panels that might be open
        }
        $('.booking-widget').slideToggle();
        e.preventDefault();

        // $('.cta-text__link open').toggle();
    });

    var nowTemp = new Date();
    var now = new Date(nowTemp.getFullYear(), nowTemp.getMonth(), nowTemp.getDate(), 0, 0, 0, 0);
    $('#arrive').fdatepicker({
        format: 'dd MM yyyy',
        initialDate: new Date(),
        minDate: new Date(),
        onRender: function (date) {
            return date.valueOf() < now.valueOf() ? 'disabled' : '';
        }
    });
    // $('#arrive').on('focus, click', function () {
    //     positionWidget();
    // });

    // // Override the widget positioning
    // positionWidget();
    // $(window).on('resize', positionWidget);

    // Custom slidedown dropdown
    $('.booking-widget select, .room-modal__form select').each(function () {
        var $theSelect = $(this);
        $theSelect.before(
            '<div class="select-slide"><div class="select-slide-title">' +
            $theSelect.find(':selected').text() +
            '</div><div class="select-slide-options"></div></div>'
            );

        // Loop through each item in the select dropdown
        var count = 1;
        $theSelect.find('option').each(function () {
            if (count > 1) {
                $theSelect
                .prev()
                .find('.select-slide-options')
                .append('<div class="select-slide-option">' + $(this).text() + '</div>');
            }
            count++;
        });
        $theSelect.hide();
    });

    $(document.body).on('click', '.select-slide-title', function () {
        $(this)
        .next()
        .slideToggle();
    });

    $(document.body).on('click', '.select-slide-option', function () {
        // Set the value
        var newValue = $(this).text();
        var fieldId = $(this)
        .parent()
        .parent()
        .next()
        .attr('id');
        $(this)
        .parent()
        .prev()
        .text(newValue);
        $(this)
        .parent()
        .parent()
        .next()
        .find('option')
        .removeAttr('selected')
        .filter(function (index) {
            return $(this).text() === newValue;
        })
        .prop('selected', 'selected');

        if (fieldId == 'booking-widget-choose') {
            changeWidget();
        }

        // Move to the next field
        $(this)
        .parent()
        .slideUp();
        openFieldAfter(
            $(this)
            .parent()
            .parent()
            );
    });

    function changeWidget() {
        var newValue = $('#booking-widget-choose').val();
        $('#booking-widget-food, #booking-widget-gift, #booking-widget-cookery-class').hide();
        $('#' + newValue).show();
    }

    // Trigger the next field to open
    function openFieldAfter(field) {
        // Skip over the replaced select box
        if (
            $(field)
            .next()
            .is('select')
            ) {
            field = $(field).next();
    }
}

    // Set the widget's position for large screens only
    function positionWidget() {
        if (window.innerWidth > 680) {
            try {
                var fieldPositon = document.getElementById('arrive').getBoundingClientRect();
                var style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML =
                '.datepicker.datepicker-dropdown.dropdown-menu { top: ' +
                fieldPositon.top +
                'px !important; left: ' +
                (fieldPositon.left - 231) +
                'px !important; }';
                document.getElementsByTagName('head')[0].appendChild(style);
            } catch (e) {
                return;
            }
        }
    }

    // Toggggle between the different widgets
    $('#booking-widget-choose').on('change', changeWidget);

    $('a[href="#book-room"]').on('click', function (e) {
        $('#room-modal').foundation('open');
        e.preventDefault();
    });

    // popup dining widget
    $('a[href="#book-table"], a[href="#book-restaurant"]').on('click', function (e) {
        $('#dining-modal-iframe').html(
            '<iframe src="https://7723fded-c4a4-4605-b717-6a890ecd2c71.resdiary.com/widget/FixedSize/GravetyeManor/3245" frameborder="0"></iframe>'
            );
        $('#dining-modal').foundation('open');
        e.preventDefault();
    });

    $('.cta-text__link').click(function(){
      $(this).toggleClass('cta-text__link-close');
    })

    // $('#booking-widget-room').on('click', function(){
    //   $('.booking-widget-choose').hide();
    //   $('.booking-widget-rooms').show();
    // });

    // $('#booking-widget-room-close').on('click', function(){
    //   $('.booking-widget-rooms').hide();
    //   $('.booking-widget-choose').show();
    // });

    // $('.cta-text__link').on('click', function(){
    //   $('.booking-widget-rooms').hide();
    //   $('.booking-widget-choose').show();
    // })




}

function initAccordiom() {
    $('.accordion').accordiom({
        openAll: false,
        showFirstItem: false,
        afterChange: function (accordionButton) {
            $(accordionButton).next('.accordionContent').append();
        }
    });
}

function initLocationMap() {
    if ($('.location-map-image').length > 0) {
        $('.location-map-image map').imageMapResize();

        $('.location-map-panel-close').on('click', function () {
            $(this).parent().removeClass('open');
        });

        $('.location-map-image .map-links a').on('click', function () {
            $('.location-map-panel.open').removeClass('open');
            $($(this).attr('href')).addClass('open');
            return false;
        });


        $('.location-map-panel .right-arrow').on('click', function () {
            $(this).parent().parent().parent().parent().removeClass('open');
            $(this).parent().parent().parent().parent().next().addClass('open');
            return false;
        });

        $('.location-map-panel .left-arrow').on('click', function () {
            $(this).parent().parent().parent().parent().removeClass('open');
            $(this).parent().parent().parent().parent().prev().addClass('open');
            return false;
        });



        $(".info-row-large .location-info").css({ 'height': ($(".info-row-large .info-image").height() + 'px') });


    }
}



function initLazyLoading() {
    var lazyImages = Array.from($("[data-style], .lazy > source, .lazy-img"));
    console.log(lazyImages)

    var options = {
        root: null,
        rootMargin: "150px 0px",
        threshold: 0,
      };

    var lazyImageObserver = new IntersectionObserver(callbackObserver, options);


    function presetImgSizeStyles(img) {
       if (img.nodeName === 'SOURCE' || img.nodeName === 'DIV' || img.classList.contains('bg-img') || typeof img.dataset.presets !== 'undefined') {
         return
       }

        img.style.width= "100%";
        img.style.height="auto";
        img.style.display="block";
        img.decoding = 'async';
      }

    function setWidthAndHeight(img) {
        if (typeof img.dataset.presets !== 'undefined') return

        window.addEventListener('resize', function(){
            img.width = img.clientWidth;
            img.height = img.clientHeight;
          });
        img.width = img.clientWidth;
        img.height = img.clientHeight;
      }
    
    function setImageViewportWidth(img) {
        var windowWidth = window.innerWidth;
        var imgWidth = img.clientWidth;
        var imgViewportWidth = Math.floor((imgWidth / windowWidth) * 100) + "vw";
        img.sizes = imgViewportWidth;
      }

    function handleImgSrcset(img) {
        setImageViewportWidth(img);
        setWidthAndHeight(img);
        img.srcset = img.dataset.srcset;
        img.src = img.dataset.src;
        delete img.dataset.srcset;
        delete img.dataset.src;
        
        lazyImageObserver.unobserve(img);
       
      }

    function handleImgTag(img) {
        setWidthAndHeight(img);
        img.src = img.dataset.src;
        delete img.dataset.src;
        lazyImageObserver.unobserve(img);
    }  

    function handleBackgroundImg(img) {
        img.style = img.dataset.style; 
        delete img.dataset;
        lazyImageObserver.unobserve(img);
      }

    function handlePictureElement(img) {
        if (img.nextElementSibling.nodeName === "IMG") {
          setWidthAndHeight(img.nextElementSibling);
          img.nextElementSibling.src = img.nextElementSibling.dataset.src;
          delete img.nextElementSibling.dataset.src;
        }
        
        img.srcset = img.dataset.srcset;
        delete img.dataset.srcset;
    
        img.parentElement.classList.remove("lazy");
        lazyImageObserver.unobserve(img);
    }  

    function callbackObserver(entries) {
        entries.forEach(function(entry) {
          var img = entry.target;
          console.log(img)
          
          presetImgSizeStyles(img)
    
          if (!entry.isIntersecting) return;
    
          if (img.parentElement.nodeName === "PICTURE") {
            handlePictureElement(img);
          }

          if (img.nodeName === "IMG" && typeof img.dataset.srcset !== 'undefined') {
            handleImgSrcset(img); 
          }

          if (img.nodeName === "IMG") {
            handleImgTag(img); 
          }

          if (img.nodeName === "DIV") {
            
            handleBackgroundImg(img);
          }

          return
        });
      }
    
      lazyImages.forEach(function (img) {
        lazyImageObserver.observe(img);
      });
}

function initHomeHeading () {
    var heading = $('.rte-container')
    
    if (window.location.pathname !== "/") return;
    heading.addClass("content-center")
    heading.css("min-height", "unset")
    heading.css("padding-right", "15px")
    heading.css("padding-left", "15px")
};